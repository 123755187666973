import {Button} from '@/components/ui/atom/Button/index.js'

import {UIHero, UIHeroLeft} from './styles/ui.js'

export default function Hero() {
  return (
    <UIHero>
      <UIHeroLeft>
        <h1 className="animate__animated animate__bounce">
          {`I'm `} <span>Oriol Puig</span>
          <br />
          <span>Frontend</span> Engineer
        </h1>
        <p>{`I'm `}a focused on mobile-first, performance and code-quality.</p>
        <Button label="HIRE ME" shape="rounded" color="secondary" />
      </UIHeroLeft>
    </UIHero>
  )
}
