import styled from 'styled-components'
import {
  buildAtomButtonColors,
  buildAtomButtonShapes,
  buildAtomButtonSizes,
  buildAtomButtonVariants
} from './builders.js'

export const UIButton = styled.button`
  &.op-AtomButton {
    --bd-atom-button: var(--bdw-atom-button) var(--bds-atom-button)
      var(--bdc-atom-button);
    --bdc-atom-button: transparent;
    --bdrs-atom-button: 0;
    --bds-atom-button: solid;
    --bdw-atom-button: 0;
    --bgc-atom-button: var(--op-c-gray);
    --c-atom-button: var(--op-c-white);
    --ff-atom-button: var(--op-ff-base);
    --fw-atom-button: var(--op-fw-bold);
    --fz-atom-button: var(--op-fz-body);
    --lh-atom-button: var(--op-lh-m);

    font-family: var(--ff-atom-button);
    font-size: var(--fz-atom-button);
    font-weight: var(--fw-atom-button);
    line-height: var(--lh-atom-button);
    border: var(--bd-atom-button);
    border-radius: var(--bdrs-atom-button);
    cursor: pointer;
    display: inline-block;
    color: var(--c-atom-button);
    background-color: var(--bgc-atom-button);

    &:hover {
      --bgc-atom-button: var(--op-c-gray-light-2);
    }

    &:focus {
      --bgc-atom-button: var(--op-c-gray-light-2);
    }

    ${buildAtomButtonColors()}
    ${buildAtomButtonVariants()}
    ${buildAtomButtonShapes()}
    ${buildAtomButtonSizes()}
  }
`
