import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import {UIButton} from './styles/ui.js'

const BASE_CLASS = 'op-AtomButton'

/**
 * Primary UI component for user interaction
 */
export const Button = ({
  color,
  size,
  shape,
  label,
  variant,
  type,
  ...props
}) => {
  return (
    <UIButton
      type={type}
      className={cx(BASE_CLASS, {
        [`${BASE_CLASS}--color-${color}`]: color,
        [`${BASE_CLASS}--variant-${variant}`]: variant,
        [`${BASE_CLASS}--size-${size}`]: size,
        [`${BASE_CLASS}--shape-${shape}`]: shape
      })}
      {...props}
    >
      {label}
    </UIButton>
  )
}

Button.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  shape: PropTypes.oneOf(['squared', 'rounded', 'circular']),
  size: PropTypes.oneOf(['s', 'm', 'l']),
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  variant: PropTypes.oneOf(['solid', 'outline', 'flat', 'link'])
}

Button.defaultProps = {
  color: 'primary',
  onClick: undefined,
  shape: 'squared',
  size: 'm',
  type: 'button',
  variant: 'solid'
}
