import {css} from 'styled-components'
import {
  ATOM_BUTTON_COLORS_SETTINGS,
  ATOM_BUTTON_SHAPES,
  ATOM_BUTTON_SIZES,
  ATOM_BUTTON_VARIANTS
} from './settings.js'

export const buildAtomButtonColors = () => {
  return ATOM_BUTTON_COLORS_SETTINGS.map(({color, variables}) => {
    return css`
      &.op-AtomButton--color-${color} {
        &.op-AtomButton--variant-solid {
          --bgc-atom-button: var(${variables.color});

          &:hover {
            --bgc-atom-button: var(${variables.colorHover});
          }

          &:focus {
            box-shadow: var(--op-bdsh-focus) var(${variables.color});
          }
        }

        &.op-AtomButton--variant-outline,
        &.op-AtomButton--variant-flat {
          --bgc-atom-button: transparent;
          --bdc-atom-button: var(${variables.color});
          --bdw-atom-button: calc(var(--op-bdw-base) / 8);
          --c-atom-button: var(${variables.color});

          &:hover {
            --bgc-atom-button: var(${variables.color}-light-4);
            --bdc-atom-button: var(${variables.color}-light-2);
            --c-atom-button: var(${variables.color}-light-2);
          }

          &:focus {
            box-shadow: var(--op-bdsh-focus) var(${variables.color});
          }
        }

        &.op-AtomButton--variant-flat {
          --c-atom-button: var(${variables.color});
        }

        &.op-AtomButton--variant-link {
          --bd-atom-button: 0;
          --bgc-atom-button: transparent;
          --c-atom-button: var(${variables.color});
          padding: 0;
          border-radius: 0;

          &:hover {
            --c-atom-button: var(${variables.color}-light-2);
            text-decoration: underline;
          }

          &:focus {
            box-shadow: var(--op-bdsh-focus) var(${variables.color});
          }
        }
      }
    `
  })
}

export const buildAtomButtonVariants = () => {
  return Object.values(ATOM_BUTTON_VARIANTS).map(variant => {
    switch (variant) {
      case ATOM_BUTTON_VARIANTS.FLAT:
        return css`
          &.op-AtomButton--variant-${variant} {
            border-color: transparent;
          }
        `
      default:
        break
    }
  })
}

export const buildAtomButtonShapes = () => {
  return Object.values(ATOM_BUTTON_SHAPES).map(shape => {
    switch (shape) {
      case ATOM_BUTTON_SHAPES.SQUARED:
        return css`
          &.op-AtomButton--shape-${shape} {
            border-radius: 0;
          }
        `
      case ATOM_BUTTON_SHAPES.ROUNDED:
        return css`
          &.op-AtomButton--shape-${shape} {
            --bdrs-atom-button: var(--op-bdrs-base);
          }
        `
      case ATOM_BUTTON_SHAPES.CIRCULAR:
        return css`
          &.op-AtomButton--shape-${shape} {
            --bdrs-atom-button: calc(var(--op-bdrs-base) * 4);
          }
        `

      default:
        break
    }
  })
}

export const buildAtomButtonSizes = () => {
  return Object.values(ATOM_BUTTON_SIZES).map(size => {
    switch (size) {
      case ATOM_BUTTON_SIZES.SMALL:
        return css`
          &.op-AtomButton--size-${size} {
            --fz-atom-button: var(--op-fz-s);
            --lh-atom-button: var(--op-lh-s);
            padding: calc(var(--op-spacing-base) * 2)
              calc(var(--op-spacing-base) * 4);
          }
        `
      case ATOM_BUTTON_SIZES.MEDIUM:
        return css`
          &.op-AtomButton--size-${size} {
            --fz-atom-button: var(--op-fz-m);
            --lh-atom-button: var(--op-lh-m);
            padding: calc(var(--op-spacing-base) * 3)
              calc(var(--op-spacing-base) * 7);
          }
        `
      case ATOM_BUTTON_SIZES.LARGE:
        return css`
          &.op-AtomButton--size-${size} {
            --fz-atom-button: var(--op-fz-l);
            --lh-atom-button: var(--op-lh-l);
            padding: calc(var(--op-spacing-base) * 4)
              calc(var(--op-spacing-base) * 8);
          }
        `

      default:
        break
    }
  })
}
