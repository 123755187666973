import styled from 'styled-components'
import {DEVICE_BREAKPOINTS} from '@/styles/layout/breakpoints.js'

export const UIHero = styled.section`
  background-color: var(--op-c-white);
  display: flex;
  justify-content: center;
  margin-bottom: calc(var(--op-spacing-base) * 12);
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
`

export const UIHeroLeft = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: calc(var(--op-spacing-base) * 8) calc(var(--op-spacing-base) * 4);
  text-align: center;

  h1 {
    font-size: var(--op-fz-h2);
    font-weight: var(--op-fw-bold);
    line-height: var(--op-lh-xxxl);
    margin-bottom: calc(var(--op-spacing-base) * 4);
    margin: 0;
    padding: 0;
    text-align: center;
    transition: all 400ms ease-in 0s;

    span {
      color: var(--op-c-secondary);
    }
  }

  @media (min-width: ${DEVICE_BREAKPOINTS.SM}) {
    flex: 1;

    h1 {
      font-size: var(--op-fz-h1);
      line-height: var(--op-lh-giant);
      text-align: inherit;
    }
  }
`

export const UIHeroRight = styled.article`
  display: none;

  @media (min-width: ${DEVICE_BREAKPOINTS.SM}) {
    background-color: var(--op-c-secondary);
    flex: 1;
    display: flex;
    align-items: center;
    padding: calc(var(--op-spacing-base) * 8) calc(var(--op-spacing-base) * 4);
    border-bottom-right-radius: 0.5rem;

    picture {
      width: 100%;
      max-width: 250px;

      img {
        border-radius: 50%;
        width: 150px;
        height: 150px;
      }
    }
  }

  @media (min-width: ${DEVICE_BREAKPOINTS.MD}) {
    justify-content: center;
  }
`
