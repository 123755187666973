import {DEVICE_BREAKPOINTS} from '@/styles/layout/breakpoints.js'
import styled from 'styled-components'

export default function Layout({children}) {
  return <UILayout>{children}</UILayout>
}

const UILayout = styled.main`
  margin-top: 70px;
  min-height: calc(100vh - 70px - 49px);

  @media (min-width: ${DEVICE_BREAKPOINTS.SM}) {
    margin: 0 auto;
    margin-top: 96px;
    min-height: calc(100vh - 96px - 49px);
    max-width: 990px;
  }
`
