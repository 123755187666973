import Head from 'next/head'
import dynamic from 'next/dynamic'

import Hero from '../components/home/Hero'
import Layout from '@/components/common/Layout/index.js'

const About = dynamic(() => import('@/components/home/About/index.js'), {
  ssr: true
})

const Experience = dynamic(
  () => import('@/components/home/Experience/index.js'),
  {
    ssr: true
  }
)

const Education = dynamic(
  () => import('@/components/home/Education/index.js'),
  {
    ssr: true
  }
)

export default function Home() {
  return (
    <>
      <Head>
        <title>Home | Oriol Puig</title>
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="description"
          content="Hi! I'm Oriol Puig, a Frontend more than 9 years of experience in software development."
        />
      </Head>
      <Hero />
      <About />
      {/* <Where /> */}
      <Education />
      <Experience />
    </>
  )
}

Home.getLayout = function getLayout(page) {
  return <Layout>{page}</Layout>
}
